import { PATHS } from 'constants/paths';

export const treatmentsSections = {
  botox: {
    title: 'Zabiegi medycyny estetycznej z botoxem',
    descriptions: [
      'W gabinecie medycyny estetycznej IDEALE oferujemy profesjonalne zabiegi z wykorzystaniem toksyny botulinowej, które są jednymi z najpopularniejszych metod na osiągnięcie gładkiej, młodo wyglądającej skóry bez oznak zmęczenia czy starzenia. Toksyna botulinowa, znana również jako botox, to sprawdzony sposób na redukcję zmarszczek mimicznych, szczególnie w obszarze czoła, wokół oczu oraz między brwiami. Nasze zabiegi są precyzyjnie dostosowane do indywidualnych potrzeb i oczekiwań każdego pacjenta, co gwarantuje naturalnie piękne efekty i zadowolenie z wyglądu.',
      'W IDEALE stosujemy najnowsze techniki aplikacji botoxu, aby maksymalnie wydobyć naturalną urodę, jednocześnie zachowując pełną ekspresję twarzy i komfort pacjentów. Nasze zabiegi nie tylko poprawiają wygląd skóry, ale także przyczyniają się do długotrwałego uczucia młodości i świeżości. Dbając o najwyższe standardy, gwarantujemy bezpieczeństwo i profesjonalizm na każdym etapie zabiegu. Zapraszamy do IDEALE, gdzie nowoczesna medycyna estetyczna łączy się z troską o zdrowy i młody wygląd.',
    ],
    popularTreatments: [
      {
        text: 'Usuwanie zmarszczek mimicznych',
        href: 'usuwanie-zmarszczek-mimicznych-bot',
      },
    ],
    url: PATHS.OFFER_BOTOX,
    popularOffersText: 'z wykorzystaniem toksyny botulinowej',
    imageAlt: 'zabiegi botoxem ideale medycyna estetyczna warszawa wola',
  },
  acid: {
    title: 'Wypełnienia kwasem hialuronowym',
    descriptions: [
      'W gabinecie medycyny estetycznej IDEALE oferujemy zaawansowane zabiegi z wykorzystaniem kwasu hialuronowego, które są kluczowe dla zachowania młodego i zdrowego wyglądu skóry. Kwas hialuronowy to jeden z najpopularniejszych składników wykorzystywanych w medycynie estetycznej do wypełniania zmarszczek oraz modelowania i nawilżania twarzy. Nasze zabiegi wykorzystujące kwas hialuronowy efektywnie przywracają skórze utraconą objętość, poprawiają kontur twarzy i zapewniają głębokie nawilżenie.',
      'Dzięki precyzyjnie dobranej metodologii i specjalistycznemu doborowi preparatów, zabiegi w IDEALE gwarantują widoczne i trwałe efekty. Te nowoczesne zabiegi są niezwykle popularne wśród osób poszukujących naturalnych sposobów na poprawę wyglądu oraz tych, którzy pragną skutecznie zwalczyć oznaki starzenia. IDEALE to miejsce, gdzie doświadczysz najlepszych zabiegów z kwasem hialuronowym, które odmładzają, odżywiają i przywracają twarzy zdrowy, młody wygląd.',
    ],
    popularTreatments: [
      {
        text: 'Wypełnianie zmarszczek kwasem hialuronowym',
        href: 'wypelnianie-zmarszczek-kwasem-hialuronowym',
      },
    ],
    url: PATHS.OFFER_HYALURONIC_ACID,
    inverted: true,
    popularOffersText: 'z wykorzystaniem kwasu hialuronowego',
    imageAlt:
      'zabiegi kwasem hialuronowym ideale medycyna estetyczna warszawa wola',
  },
  stimmulants: {
    title: 'Stymulatory tkankowe',
    descriptions: [
      'W gabinecie medycyny estetycznej IDEALE oferujemy innowacyjne zabiegi z wykorzystaniem stymulatorów tkankowych, które stanowią przełom w dziedzinie odmładzania i regeneracji skóry. Stymulatory tkankowe to zaawansowane preparaty, które aktywizują naturalne procesy regeneracyjne organizmu, prowadząc do poprawy jędrności skóry oraz jej gęstości. Są to jedne z najbardziej popularnych zabiegów w naszym gabinecie, cenione za ich zdolność do długotrwałej poprawy wyglądu skóry.',
      'Zabiegi te szczególnie polecamy osobom, które chcą uzyskać naturalnie młodszy wygląd bez użycia inwazyjnych metod. Stymulatory tkankowe doskonale sprawdzają się w poprawie konturu twarzy, redukcji drobnych linii oraz zmarszczek, a także w znaczącym zwiększeniu elastyczności skóry. W IDEALE stosujemy tylko sprawdzone i bezpieczne stymulatory tkankowe, co zapewnia naszym klientom najlepsze efekty oraz wysoki poziom satysfakcji z przeprowadzonych zabiegów. Zapraszamy do skorzystania z naszej oferty i doświadczenia wyjątkowych korzyści, jakie niosą ze sobą zabiegi z użyciem stymulatorów tkankowych w IDEALE.',
    ],
    popularTreatments: [
      {
        text: 'Molekuła Młodości H+L ',
        href: 'biostymulator-tkankowy-profhilo',
      },
      {
        text: 'Zabieg z Kwasem Polimlekowym',
        href: 'kwas-polimlekowy-sculptra',
      },
    ],
    url: PATHS.OFFER_TISSUE_STIMULATORS,
    popularOffersText: 'z wykorzystaniem toksyny botulinowej',
    imageAlt:
      'zabiegi stymulatory tkankowe ideale medycyna estetyczna warszawa wola',
  },
  mesotherapy: {
    title: 'Mezoterapia',
    descriptions: [
      'W gabinecie medycyny estetycznej IDEALE oferujemy specjalistyczne zabiegi mezoterapii skóry, które są niezwykle popularne wśród naszych klientów, dzięki ich skuteczności w poprawie wyglądu i kondycji skóry. Mezoterapia to zaawansowana metoda polegająca na mikronakłuwaniu skóry i aplikacji specjalnie dobranych koktajli zawierających witaminy, minerały, kwas hialuronowy i inne odżywcze składniki aktywne. Zabiegi te intensywnie regenerują skórę, poprawiają jej nawilżenie, elastyczność oraz ogólny wygląd.',
      'Zabiegi mezoterapii w IDEALE są idealne dla osób szukających efektywnych rozwiązań mających na celu poprawę wyglądu skóry twarzy, szyi, dekoltu oraz innych obszarów ciała. Dzięki mezoterapii nasi klienci mogą cieszyć się zdrowym wyglądem skóry, zminimalizować oznaki starzenia i przywrócić skórze młodzieńczy blask. Nasz zespół ekspertów zapewnia najwyższą jakość usług, stosując tylko najlepsze, sprawdzone preparaty, co gwarantuje bezpieczeństwo i satysfakcję z przeprowadzonych zabiegów. Zapraszamy do IDEALE, aby doświadczyć korzyści, jakie niesie ze sobą profesjonalna mezoterapia, dostosowana do indywidualnych potrzeb każdego klienta.',
    ],
    popularTreatments: [
      {
        text: 'Mezoterapia igłowa - kwas hialuronowy',
        href: 'mezoterapia-neauvia-hydro-deluxe',
      },
    ],
    url: PATHS.OFFER_MESOTHERAPY,
    inverted: true,
    popularOffersText: 'mezoterapii',
    imageAlt: 'zabiegi mezoterapia ideale medycyna estetyczna warszawa wola',
  },
};

export const faqContent = {
  title: 'Najczęsciej zadawane pytania',
  subtitle: '',
  faqData: [
    {
      question: 'Lorem',
      answer: 'ipsum',
    },
    {
      question: 'Lorem',
      answer: 'ipsum',
    },
    {
      question: 'Lorem',
      answer: 'ipsum',
    },
  ],
};
